





















































import {Component, Prop} from 'vue-property-decorator';
import {Action, Getter, Mutation} from 'vuex-class';
import {Unsubscribe} from 'firebase/firestore';
import {pad} from '@/utils/helpers';
import VideoCall from '@/components/mixins/VideoCall';
import {mixins} from 'vue-class-component';

@Component({
  name: 'video-call-minimized'
})
export default class VideoCallMinimized extends mixins(VideoCall) {
  @Prop() value!: boolean;

  unsubscribeVideo: Unsubscribe | null = null;
  minutes: string = pad(parseInt(String(this.totalSeconds / 60)));
  seconds: string = pad(this.totalSeconds % 60);
  timeout: NodeJS.Timeout | null = null;

  @Getter videoSession: any;
  @Getter videoTotalSeconds!: number;

  @Mutation setVideoTotalSeconds;

  @Action endVideoCall;
  @Action bindVideoSession;
  @Action normalizeVideo;

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get title() {
    return this.videoSession && (this.videoSession.title || this.videoSession.from.name) || 'Video Session';
  }

  get membersCount() {
    return this.videoSession && Object.keys(this.videoSession.videoSession.members).length;
  }

  get timePassed() {
    return `${this.minutes}:${this.seconds}`;
  }

  get totalSeconds() {
    return this.videoTotalSeconds;
  }

  set totalSeconds(value) {
    this.setVideoTotalSeconds(value);
  }

  onNormalizeVideo() {
    this.normalizeVideo(this.totalSeconds);
  }

  async onEndVideoCall() {
    await this.endVideoCall();
    this.close();
  }

  close() {
    this.show = false;
    this.$emit('close-dialog');
  }

  private startTimer() {
    this.timeout = setInterval(this.setTime, 1000);
  }

  private setTime() {
    this.totalSeconds += 1;
    this.seconds = pad(this.totalSeconds % 60);
    this.minutes = pad(parseInt(String(this.totalSeconds / 60)));
  }

  async created() {
    console.log('VIDEO_CALL_MINIMIZED => created');
    this.unsubscribeVideo = await this.bindVideoSession();
    this.startTimer();
  }

  async beforeDestroy() {
    console.log('VIDEO_CALL_MINIMIZED => before destroy');
    if (this.timeout) {
      clearInterval(this.timeout);
    }
    if (this.unsubscribeVideo) {
      this.unsubscribeVideo();
    }
  }
}
